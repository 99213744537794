@import '../../assets/scss/components/_variables';
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';

.ant-tabs .ant-tabs-nav-list .ant-tabs-ink-bar {
    background-color: $black;
}

.home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .primary-button {
        display: flex;
        padding: 1rem 2.5rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        border-radius: 1.25rem;
        background: var(--Primary, #000);
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
        color: var(--White-White-100, #fff);
    }
}

.group-recomment-box {
    max-width: 1600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 56px 56px 56px 56px;
    gap: 112px;
    @include media-breakpoint-down(md) {
        padding: 32px;
        gap: 1rem;
    }
    @include media-breakpoint-up(md) {
        gap: 72px;
    }
    @include media-breakpoint-up(lg) {
        gap: 112px;
    }
    @include media-breakpoint-up(xl) {
        gap: 112px;
    }
    .recommen-products-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
        gap: 32px;
        @include media-breakpoint-down(md) {
            gap: 1rem;
        }

        .text-header {
            width: 100%;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            @include media-breakpoint-down(md) {
                padding: 16px;
            }
        }

        figure {
            margin: 0;
            padding: 0;
            background: #fff;
            overflow: hidden;
        }

        figure:hover {
            bottom: -36px;
            opacity: 1;
        }

        .hover-zoom-in figure img {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transition: 0.3s ease-in-out;
            transition: 0.3s ease-in-out;
        }
        .hover-zoom-in figure:hover img {
            -webkit-transform: scale(1.3);
            transform: scale(1.1);
        }

        .image-products {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 24px;

            @media (max-width: 768px) {
                flex-direction: column; // เปลี่ยนทิศทางเมื่อหน้าจอเล็ก
            }
        }

        .image-box {
            position: relative;
            width: 100%;
            overflow: hidden;
            &:hover {
                cursor: pointer;
            }
        }

        .image-box img {
            width: 100%;
            height: auto;
            object-fit: contain;
            transition: 0.5s all ease-in-out;
        }
    }
}

.group-content-box {
    max-width: 1600px;
    display: flex;
    flex-direction: column;
    padding: 56px;
    gap: 72px;
    @include media-breakpoint-down(md) {
        padding: 32px;
        gap: 1rem;
    }
    @include media-breakpoint-up(md) {
        gap: 72px;
    }
    @include media-breakpoint-up(lg) {
        gap: 112px;
    }
    @include media-breakpoint-up(xl) {
        gap: 112px;
    }
    .content-box {
        width: 100%;
        display: flex;
        // justify-content: space-between;
        align-items: center;
        gap: 80px;
        @include media-breakpoint-down(md) {
            gap: 20px;
        }
        .container-text-box {
            div ul {
                @include media-breakpoint-down(md) {
                    margin-left: 16px;
                }
            }
        }
    }
    .row {
        flex-direction: row;
        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
    }
    .row-reverse {
        flex-direction: row-reverse;
        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
    }
    .img {
        width: 100%;
        aspect-ratio: 1/1;
        height: auto;
        flex-shrink: 0;
        align-self: center;
        object-fit: cover;
        @media (min-width: 768px) {
            width: 20rem;
            height: 20rem;
        }
        @media (min-width: 800px) {
            width: 22rem;
            height: 22rem;
        }
        @media (min-width: 992px) and (max-width: 1199px) {
            width: 30rem;
            height: 30rem;
        }
        @media (min-width: 1200px) {
            width: 34rem;
            height: 34rem;
        }
    }
}

.another-product-container {
    width: 100%;
    background-color: #eff1f9;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    padding: 56px;
    @include media-breakpoint-down(md) {
        padding: 1rem;
    }
    .text-header {
        width: 100%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        @include media-breakpoint-down(md) {
            padding: 16px;
        }
    }
}

.group-anothers {
    margin: 12px 0px 20px 0px;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(md) {
        flex-direction: column;
    }
    .container {
        position: relative;
        // width: 100%;
        // height: 100%;
        cursor: pointer;

        .background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            flex-shrink: 0;
            background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0) 0%,
                #000 100%
            );
        }
        .text-container {
            position: absolute;
            top: 80%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            color: white;
            z-index: 1; /* Ensure text is above the image */
        }

        .another-product-header-text {
            width: 19.5625rem;
            color: var(--White-White-100, #fff);
            text-align: center;
            font-style: normal;
            line-height: normal;
            margin-bottom: 0.75rem;

            margin: 0px 12px;
            font-weight: 600;
            line-break: auto;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            line-height: 1.5rem;
            min-height: 3rem !important;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
            @include media-breakpoint-up(md) {
                width: 8rem;
            }
            @include media-breakpoint-up(xl) {
                width: 19.5625rem;
            }
        }

        .women-text {
            color: var(--White-White-100, #fff);
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            bottom: 20%;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        .image {
            aspect-ratio: 4 / 5; // กำหนดอัตราส่วน
            object-fit: cover; // ปรับภาพให้ครอบคลุมทั้งองค์ประกอบโดยไม่เปลี่ยนอัตราส่วน
            width: 100%; // กำหนดให้เต็มความกว้างขององค์ประกอบ
            height: auto; // ปรับตามอัตราส่วนที่กำหนด
            flex-shrink: 0; // ป้องกันการยุบขององค์ประกอบเมื่อมีการเปลี่ยนแปลงในเลย์เอาต์

            @include media-breakpoint-down(md) {
                aspect-ratio: 4 / 5; // กำหนดอัตราส่วน
                width: 328px; // กำหนดความกว้างสำหรับจอเล็ก
                height: auto; // ปรับตามอัตราส่วน
            }

            @include media-breakpoint-up(md) {
                aspect-ratio: 4 / 5; // กำหนดอัตราส่วน
                width: 14rem; // กำหนดความกว้างสำหรับจอใหญ่ขึ้น
                height: auto; // ปรับตามอัตราส่วน
            }

            @include media-breakpoint-up(lg) {
                aspect-ratio: 4 / 5; // กำหนดอัตราส่วน
                width: 18rem; // กำหนดความกว้างสำหรับจอที่ใหญ่กว่า
                height: auto; // ปรับตามอัตราส่วน
            }

            @media (min-width: 1300px){
                width: 24rem;  // กำหนดความกว้างสำหรับช่วงนี้
                height: auto;  // ให้ปรับตามอัตราส่วน
            }
        }
    }
}

.blogs {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin: 56px 0;
    gap: 16px;

    @include media-breakpoint-down(md) {
        // margin-bottom: 16px;
    }
    .group-blogs {
        width: 100%;
        display: flex;
        flex-direction: row;
        text-align: center;
        justify-content: center;
        @include media-breakpoint-down(md) {
            gap: 8px;
            flex-direction: column;
            align-items: center;
        }
        .blog-container {
            width: calc(100% / 3);
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            cursor: pointer;

            img {
                width: 100%;
                aspect-ratio: 16 / 9;
                object-fit: cover;
            }
            .blog-text-container {
                display: flex;
                flex-direction: column;
                gap: 8px;
                width: 100%;
                text-align: left;
                padding: 0 8px;
                .text-description {
                    color: black;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    :first-child {
                        margin-top: 0;
                    }
                }
            }
            @include media-breakpoint-down(md) {
                flex-direction: row-reverse;
                align-items: start;
                width: 80%;
                img {
                    max-width: 200px;
                    aspect-ratio: 16 / 9;
                    object-fit: cover;
                }
                .blog-text-container {
                    .text-description {
                        color: black;
                        display: none;
                    }
                }
            }
        }
    }
}
.centered-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.group-suggestions-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    padding: 56px;
    gap: 40px;
    @include media-breakpoint-down(md) {
        padding: 16px;
        gap: 16px;
    }

    .text-header {
        width: 100%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        font-weight: 600;
    }
    .group-button {
        width: 100%;
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: center;
        justify-content: center;
        gap: 40px;
        @include media-breakpoint-down(md) {
            gap: 16px;
        }
    }
}

.without-line-btn {
    padding: 16px 40px;

    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 30%;
    height: auto;
    border-radius: 20px;
    background: $primary;
    color: $white;
    border: 2px solid var(--Primary, #000);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    &:hover,
    &:focus {
        color: $white;
        background: #4096ff;
        border: 2px solid var(--Primary, #4096ff);
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    }
    @include media-breakpoint-down(md) {
        padding: 6px 0px;
        margin: 16px;
        width: 40%;
    }
}

.with-line-btn {
    padding: 16px 40px;

    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 30%;
    height: auto;
    border-radius: 20px;
    background: $white;
    color: $primary;
    border: 2px solid var(--Primary, #000);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    &:hover,
    &:focus {
        color: $white;
        background: #4096ff;
        border: 2px solid var(--Primary, #4096ff);
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    }
    @include media-breakpoint-down(md) {
        padding: 4px 10px;
    }
}

.ant-tabs {
    max-width: 100%;
    // max-width: 1170px;
    // @media (max-width: 1199px) {
    //     max-width: 864px;
    // }
    // @media (max-width: 991px) {
    //     max-width: 672px;
    // }
    // @media (max-width: 767px) {
    //     max-width: 328px;
    // }
}
