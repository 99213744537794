.page-content {
    color: black;
    margin: 32px 56px 32px; /* หน้าจอขนาดเล็ก (xs) */
    display: flex !important;
    justify-content: center !important;
}

@media (min-width: 576px) {
    .page-content {
        margin: 12px 56px 48px; /* หน้าจอขนาดเล็กกลาง (sm) */
    }
}

@media (min-width: 768px) {
    .page-content {
        margin: 20px 56px 56px; /* หน้าจอขนาดกลาง (md) */
    }
}

@media (min-width: 992px) {
    .page-content {
        margin: 20px 56px 56px; /* หน้าจอขนาดใหญ่ (lg) */
    }
}
