@import '../components/_variables.scss';
@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/mixins/breakpoints";

.fix-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    padding: 0.5rem;
    margin: 0 0.5rem;
    .login_text:hover {
        color: $info-active;
        cursor: pointer;
    }
}
@media (max-width: 740px) {
    .fix-nav.open {
        display: none;
    }
}
.login-footer {
    font-size: 14px;
    padding: 0rem;
    margin: 0 0rem;
    .login_text:hover {
        color: $info-active;
        cursor: pointer;
    }
}

.login-icon-mobile {
    @include media-breakpoint-up(md) {
        display: none;
    }
}
.login-icon-web {
    @include media-breakpoint-down(md) {
        display: none;
    }
}