@import '../components/variables.scss';

.modal_card {
    .ant-modal-content {
        display: flex;
        padding: 56px 40px;
        flex-direction: column;
        align-items: center;
        text-align: center;
        border-radius: 24px;
        box-shadow:
            0px 6px 16px 0px rgba(0, 0, 0, 0.08),
            0px 3px 6px -4px rgba(0, 0, 0, 0.12),
            0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
    .head_text {
        display: flex;
        height: 120px;
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }
    h1 {
        margin: 0px;
        /* Header */

        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    h2 {
        margin: 0px;
        /* Normal */

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .ant-form-item-explain,
    .ant-form-item-extra,
    .ant-form-item-validate-message {
        text-align: left;
        font-size: 12px;
        color: $danger;
    }
    .ant-modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }
}

@media (max-width: 480px) {
    .modal_card {
        .ant-modal-content {
            padding: 36px 20px;
            border-radius: 12px;
        }
        .head_text {
            height: 80px;
            gap: 8px;
        }
        h1 {
            margin: 0px;
            /* Header */

            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        h2 {
            margin: 0px;
            /* Normal */

            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .ant-form-item-explain,
        .ant-form-item-extra,
        .ant-form-item-validate-message {
            text-align: left;
            font-size: 10px;
        }

        .ant-modal-body {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    }
}
