.title {
    color: black;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.5rem;
    max-height: 3rem;
    margin: 12px 0px;
}

.description {
    color: black;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.5rem;
    max-height: 4.5rem;
}
