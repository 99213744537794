@import '../../assets/scss/components/_variables';
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';

.header-container {
    h2 {
        margin: 0px;
        padding: 0px;
        color: #000;
        text-align: center;

        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        @include media-breakpoint-down(md) {
            font-size: 16px;
        }
    }
}

.image-products {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
    width: 80%;
    padding: 40px;
    gap: 40px;
    @media screen and (max-width: 1180px) {
        flex-direction: row;
        width: 80%;
    }
    @media screen and (max-width: 1024px) {
        flex-direction: column;
        gap: 20px;
        width: 80%;
    }
    @media screen and (width: 1024px) and (height: 768px) {
        flex-direction: row;
        width: 100%;
    }
    @media screen and (max-width: 932px) {
        flex-direction: row;
        width: 100%;
    }
    @media screen and (max-width: 820px) {
        flex-direction: column;
        gap: 20px;
        width: 100%;
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
        width: 100%;
        margin-top: 20px;
        padding: 0;
    }
    @media screen and (width: 667px) {
        flex-direction: row;
        width: 100%;
        padding: 0;
        margin-top: 20px;
    }
    img {
        cursor: pointer;
        &:hover {
            -webkit-transform: scale(1.3);
            transform: scale(1.1);
            transition: 0.5s;
        }
    }
    .container {
        position: relative;
        width: 100%;
    }
    .text-overlay {
        position: absolute;
        top: 80%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: white;
        z-index: 1; /* Ensure text is above the image */
        font-size: 16px;

        font-weight: 700;
        width: 100%;
        cursor: pointer;
        @include media-breakpoint-down(md) {
            font-size: 8px;
        }
    }
}
