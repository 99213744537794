@import '../../../../../node_modules//bootstrap/scss/functions';
@import '../../../../../node_modules/bootstrap/scss/variables';
@import '../../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../../../assets/scss/components/variables';

.perferred-option-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    padding: 56px;
    gap: 80px;
    .text-container {
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 40px;
        @include media-breakpoint-down(md) {
            gap: 16px;
        }
        .header {
            color: #000;

            font-size: 36px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            @include media-breakpoint-down(md) {
                font-size: 24px;
            }
        }
        .text-description {
            color: #000;

            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    .download-container {
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 47px;
        @include media-breakpoint-down(md) {
            gap: 16px;
            margin-bottom: 16px;
        }
        img {
            width: 132px;
            height: 163px;
            @include media-breakpoint-down(md) {
                width: 72px;
                height: 88px;
            }
        }
        .text-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            gap: 24px;
            @include media-breakpoint-down(md) {
                gap: 12px;
            }
            .text-description {
                color: #000;

                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                @include media-breakpoint-down(md) {
                    font-size: 16px;
                }
            }
            .download {
                color: #1777ff;

                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                cursor: pointer;
                @include media-breakpoint-down(md) {
                    font-size: 16px;
                }
            }

            .download.disabled {
                color: #b2b2b2;
                cursor: not-allowed;
            }
        }
    }
}
