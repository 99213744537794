@import '../../../../../node_modules//bootstrap/scss/functions';
@import '../../../../../node_modules/bootstrap/scss/variables';
@import '../../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../../../assets/scss/components/variables';

.perferred-option-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    padding: 56px;
    gap: 80px;

    :where(.css-dev-only-do-not-override-1ug4gvy).ant-steps {
        width: 70%;
    }

    @include media-breakpoint-down(md) {
        padding: 16px !important;
        gap: 24px !important;
        margin-top: 24px !important;
    }
    .radio-button-container {
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        gap: 40px;
        @include media-breakpoint-down(md) {
            width: 80% !important;
            gap: 32px !important;
        }
        .group-flex-start {
            width: 60%;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: start;
            text-align: start;
            gap: 20px;
            :where(
                    .css-dev-only-do-not-override-1ug4gvy
                ).ant-space-gap-row-small {
                row-gap: 20px;
            }
            @include media-breakpoint-down(md) {
                width: 100% !important;
                gap: 16px !important;
            }
            @include media-breakpoint-up(md) {
                width: 100%;
            }
            .text-descriptions {
                color: black;
                margin: 0px;
                padding: 0px;
                ul {
                    text-align: start;
                    @include media-breakpoint-down(md) {
                        padding-left: 16px;
                    }
                }
                ol {
                    text-align: start;
                    @include media-breakpoint-down(md) {
                        padding-left: 16px;
                    }
                }
            }
        }
    }
    .button {
        width: 70%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: flex-end;
        @include media-breakpoint-down(md) {
            width: 90%;
        }
    }
}

.custom-form-item {
    .ant-form-item {
        margin-bottom: 0;

        .ant-form-item-explain-error {
            position: absolute;
            top: 100%;
            left: 0;
            margin-top: 4px;
        }
    }
}
