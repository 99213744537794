@import '../../assets/scss/components/_variables';
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';

.product-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;

    .img-header {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .info-web {
        min-width: 800px;
        @include media-breakpoint-down(lg) {
            display: none;
        }
        .detail-container {
            width: 100%;
            max-width: 1600px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            text-align: start;
            justify-content: space-between;
            padding: 3.5rem;
            gap: 2.5rem;
            .text-container {
                color: black;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 2.5rem;
                .text-header {
                    color: $black;
                    font-size: 2.25rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
                .text-description {
                    // display: flex;
                    // flex-direction: column;
                    color: black;

                    // font-size: 0.875rem;
                    // font-style: normal;
                    // font-weight: 400;
                    // line-height: normal;
                    // gap: 2.5rem;
                    // .text {
                    //     width: 100%;
                    // }
                    :first-child {
                        margin-top: 0;
                    }
                }
            }
            .img-container {
                display: flex;
                flex-direction: row;
                align-items: start;
                justify-content: space-between;
                gap: 2.5rem;
                .left-img {
                    .img1 {
                        width: 16rem;
                        flex-shrink: 0;
                        object-fit: cover;
                        aspect-ratio: 9/16;
                        justify-items: start;
                        @media (max-width: 1200px) {
                            width: 15rem;
                        }
                    }
                }
                .right-img {
                    display: flex;
                    align-content: center;
                    align-items: center;
                    justify-content: center;
                    gap: 2.5rem;
                    margin-top: 120px;
                    .img2 {
                        width: 16rem;
                        flex-shrink: 0;
                        object-fit: cover;
                        aspect-ratio: 9/16;
                        @media (max-width: 1200px) {
                            width: 15rem;
                        }
                    }
                }
            }
        }
    }
    .info-mobile {
        @include media-breakpoint-up(lg) {
            display: none;
        }
        .detail-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            text-align: start;
            justify-content: space-between;
            padding: 3.5rem;
            gap: 2.5rem;
            @include media-breakpoint-down(md) {
                padding: 0rem;
                gap: 1rem;
            }
            .text-container {
                min-width: 600px;
                width: 100%;
                color: black;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 2.5rem;
                @include media-breakpoint-down(md) {
                    padding: 20px;
                    min-width: 372px;
                }
                .text-header {
                    color: $black;
                    font-size: 2.25rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    @include media-breakpoint-down(md) {
                        font-size: 1.25rem;
                    }
                }
            }
            .img-container {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: start;
                justify-content: center;
                gap: 2.5rem;
                @include media-breakpoint-down(md) {
                    gap: 1rem;
                    padding: 0.8rem;
                    margin-top: 1rem;
                }
                .left-img {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    gap: 2.5rem;
                    @include media-breakpoint-down(md) {
                        gap: 1rem;
                    }
                    .img1 {
                        width: 16rem;
                        flex-shrink: 0;
                        object-fit: cover;
                        aspect-ratio: 9/16;
                        @include media-breakpoint-down(md) {
                            width: 15rem;
                            flex-shrink: 0;
                            object-fit: cover;
                            aspect-ratio: 9/16;
                        }
                        @media (max-width: 576px) {
                            width: 10rem;
                            flex-shrink: 0;
                            object-fit: cover;
                            aspect-ratio: 9/16;
                        }
                    }
                }
                .right-img {
                    display: flex;
                    align-content: center;
                    align-items: center;
                    justify-content: center;
                    gap: 2.5rem;
                    .img3 {
                        width: 16rem;
                        flex-shrink: 0;
                        object-fit: cover;
                        aspect-ratio: 9/16;
                        margin-top: 80px;
                        @media (max-width: 768px) {
                            width: 15rem;
                            flex-shrink: 0;
                            object-fit: cover;
                            aspect-ratio: 9/16;
                        }
                        @media (max-width: 576px) {
                            width: 10rem;
                            flex-shrink: 0;
                            object-fit: cover;
                            aspect-ratio: 9/16;
                        }
                    }
                }
            }
        }
    }
}

.carousel {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    aspect-ratio: 13 / 5;
    @media (min-width: 760px) {
        aspect-ratio: 13 / 5;
    }
    @media (max-width: 759px) {
        aspect-ratio: 4 / 5;
    }
}

.img-scale {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.banner-mobile {
    width: 100%;
    @include media-breakpoint-up(md) {
        display: none;
    }
}

.banner-web {
    width: 100%;
    @include media-breakpoint-down(md) {
        display: none;
    }
}

.slide {
    object-fit: cover;
}
