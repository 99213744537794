/* styles.css */

$white: #ffffff !default;
$gray-100: #f5f8fa !default;
$gray-200: #eff2f5 !default;
$gray-300: #e4e6ef !default;
$gray-400: #b5b5c3 !default;
$gray-500: #a1a5b7 !default;
$gray-600: #7e8299 !default;
$gray-700: #5e6278 !default;
$gray-800: #3f4254 !default;
$gray-900: #181c32 !default;
$black: #000000 !default;
$text-muted: $gray-500 !default;

// gray colors map
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900,
) !default;

// custom colors
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;

// colors map เรียกใช้ ex. colors-blue
$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "gray": $gray-600,
) !default;

// Primary colors
$primary: #000000 !default;
$primary-active: #999999 !default;
$primary-light: #cccccc !default;
$primary-inverse: #f4f4f4 !default;

// Secondary colors
$secondary: #ffffff !default;
$secondary-active: $gray-400 !default;
$secondary-light: $gray-100 !default;
$secondary-inverse: $gray-800 !default;

// Success colors
$success: #52c41a !default;
$success-hover: #73d13d !default;
$success-active: #389e0d !default;
$success-light: #52c41a !default;
$success-inverse: $white !default;
$success-deprecated-background: #f6ffed !default;
$success-deprecated-border: #b7eb8f !default; 

// Inco colors
$info: #1890ff !default;
$info-hover: #40a9ff;
$info-active: #096dd9 !default;
$info-light: #eee5ff !default;
$info-inverse: $white !default;
$info-deprecated-background: #e6f7ff;
$info-deprecated-border: #91d5ff;

// Warning colors
$warning: #faad14 !default;
$warning-hover: #ffc53d !default;
$warning-active: #d48806 !default;
$warning-light: #fff4de !default;
$warning-inverse: $white !default;
$warning-deprecated-background: #fffbe6 !default;
$warning-deprecated-border: #ffe58f !default;

// Danger colors
$danger: #ff4d4f !default;
$danger-hover: #ff7875 !default;
$danger-active: #d9363e !default;
$danger-light: #ffe2e5 !default;
$danger-inverse: $white !default;
$danger-deprecated--background: #fff2f0 !default;
$danger-deprecated--border: #ffccc7 !default;

// White colors
$white: $white !default;
$white-active: $gray-100 !default;
$white-inverse: $gray-600 !default;

$navbar-color-background: $secondary !default;
$navbar-color-text: $primary !default;

$footer-color-background: $primary !default;
$footer-color-text: $secondary !default;


// Theme colors map เรียกใช้ ex. theme-colors-white
$theme-colors: (
  "white": $white,
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
) !default;

// Hover colors map เรียกใช้ ex. theme-active-colors-white
$theme-active-colors: (
  "white": $white-active,
  "primary": $primary-active,
  "secondary": $secondary-active,
  "success": $success-active,
  "info": $info-active,
  "warning": $warning-active,
  "danger": $danger-active,
) !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `13px`
$font-size-lg: $font-size-base * 1.075 !default; // 14.04px
$font-size-sm: $font-size-base * 0.925 !default; // 12.025px

// Heading sizes
$h1-font-size: $font-size-base * 1.75 !default; // 22.75px
$h2-font-size: $font-size-base * 1.5 !default; // 19.50px
$h3-font-size: $font-size-base * 1.35 !default; // 17.55px
$h4-font-size: $font-size-base * 1.25 !default; // 16.25px
$h5-font-size: $font-size-base * 1.15 !default; // 14.95px
$h6-font-size: $font-size-base * 1.075 !default; // 13.97px