.blog-card {
    display: grid;
    grid-template-columns: 180px auto;
    grid-gap: 8px;
    margin: 4px 0;
    cursor: pointer;
    @media (max-width: 992px) {
        grid-template-columns: 200px auto;
    }
}
.ellipsis-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    :first-child {
        margin-top: 0;
    }
}
