@import '../components/_variables.scss';
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';

.footer {
    height: auto;
    width: 100%;
}

.card-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    .logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        // height: 50px;
    }
    .content {
        width: 80%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-bottom: 10px;
    }
    .link-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5px;
        color: #ffffff;
    }
    hr {
        width: 100%;
        border: 1px solid #ccc; /* Change the color as needed */
        margin-top: 20px;
    }
}

.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: $white;
    .menu-open {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        background-color: rgba(128, 128, 128, 0.5);
        z-index: 199;
        @include media-breakpoint-up(md) {
            display: none;
        }
    }
    .non-open {
        display: none;
    }
}

nav {
    width: 100%;
    height: 65px;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $navbar-color-background;
    color: $navbar-color-text;
    position: sticky;
    top: 0;
    z-index: 200;
}

nav .title {
    font-size: 1.5rem;
    // margin: 1rem;
    font-weight: bold;
    text-decoration: none;
    color: $navbar-color-text;
}

nav ul {
    display: flex;
    gap: 15px;
}

nav ul li {
    list-style: none;
}

nav ul li a {
    display: block;
    text-decoration: none;
    color: $black;
    padding: 0.5rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

nav ul li a:not(.active):hover {
    background-color: none;
}

nav .menu {
    display: none;
    position: absolute;
    top: 1.5rem;
    right: 0.5rem;
    flex-direction: column;
    justify-content: space-between;
    width: 1.8rem;
    height: 1.8rem;
}

nav .menu span {
    height: 0.4rem;
    width: 100%;
    background-color: $black;
    border-radius: 0.2rem;
}

@media (max-width: 740px) {
    nav .menu {
        display: flex;
        width: 1.6rem;
        height: 1.3rem;
        top: 18px;
        right: 8px;
    }

    nav {
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        height: 58px;
    }
    .logo {
        display: flex;
        position: relative;
        top: 16px;
        left: 8px;
    }
    nav .menu span {
        height: 0.2rem;
    }

    nav ul {
        display: none;
        flex-direction: column;
        width: 100%;
        margin-bottom: 1.6rem;
        gap: 0px;
        background: $white;
        padding-bottom: 14px;
    }

    nav ul.open {
        display: flex;
        align-items: center;
    }

    nav ul.open .primary-button {
        display: unset;
        padding: 10px 20px;
        margin-inline: 10px;
        justify-content: center;
        align-items: center;
        gap: 0px;
        border-radius: 0px;
        background: none;
        box-shadow: none;
        color: $black;
        text-decoration: none;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 12px;
        margin-block-end: 0px;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 0px;
    }

    nav ul li {
        width: 100%;
        text-align: center;
    }

    nav ul li a {
        margin: 0.2rem 0.5rem;
    }
    nav ul li span {
        display: block;
        text-decoration: none;
        color: $black;
        padding: 0.5rem;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
