@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../assets/scss/components/variables';

.order-detail-container {
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
        color: black;
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        max-width: 800px;
        gap: 30px;

        .button-container {
            display: flex;
            align-self: end;
        }
    }

    .text-header {
        color: #000;
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        @include media-breakpoint-down(md) {
            font-size: 20px !important;
        }
    }

    .text-description {
        color: black;
        margin: 0px;
        padding: 0px;
        ul {
            text-align: start;
            @include media-breakpoint-down(md) {
                padding-left: 16px;
            }
        }
        ol {
            text-align: start;
            @include media-breakpoint-down(md) {
                padding-left: 16px;
            }
        }
    }

    .group-flex-start {
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        text-align: start;
        gap: 40px;

        @include media-breakpoint-down(md) {
            width: 100% !important;
            gap: 16px !important;
        }

        @include media-breakpoint-up(md) {
            width: 100%;
        }
    }

    .summaryCard {
        width: 60%;
        text-align: left;
        background-color: #f7f8fa;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        @include media-breakpoint-down(lg) {
            width: 60% !important;
        }
        @include media-breakpoint-down(md) {
            width: 90% !important;
        }
    }

    .summaryContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .summaryContent p {
        margin: 0;
    }

    .cardAccount {
        display: flex;
        width: 60%;
        flex-direction: row;
        background-color: #f7f8fa;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        @include media-breakpoint-down(lg) {
            width: 60% !important;
        }
        @include media-breakpoint-down(md) {
            width: 90% !important;
            align-items: center;
            flex-direction: column;
        }

        .leftCard {
            width: 40%;
            padding: 5%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .rightCard {
            width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            text-align: left;

            .clipboard {
                cursor: 'pointer';
                display: 'flex';
                gap: '12px';
            }
            @include media-breakpoint-down(md) {
                width: 90% !important;
                text-align: center;
                .clipboard {
                    width: 100%;
                    justify-content: center;
                }
            }
        }
    }

    .cardAccount p {
        color: #000;
    }
}

.head_content {
    display: flex;
    flex-direction: row;
    justify-items: center;
    width: 100%;
    gap: 10px;
    color: #000;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    .text_back {
        cursor: pointer;
    }
    @media (max-width: 767.98px) {
        font-size: 12px;
    }
}
