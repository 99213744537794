body {
    margin: 0;
}

.content {
    > div {
        /* margin: 16px 0; */
        /* padding: 24px; */
        min-height: 100%;
    }
}
