@import '../../assets/scss/components/variables';
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';

.carousel {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    aspect-ratio: 13 / 5;
    @media (min-width: 760px) {
        aspect-ratio: 13 / 5;
    }
    @media (max-width: 759px) {
        aspect-ratio: 4 / 5;
    }
}
.pointer {
    cursor: pointer;
}



.slide-hidden {
    display: none;
}

.img-scale {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.indicators {
    display: flex;
    position: absolute;
    bottom: 1rem;
}

.dots {
    position: absolute;
    bottom: 1rem;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    @include media-breakpoint-down(md) {
        bottom: 0.5rem;
    }
    @include media-breakpoint-up(md) {
        bottom: 0.6rem;
    }
    @include media-breakpoint-up(lg) {
        bottom: 1rem;
    }
}

.dots li {
    list-style: none;
    width: 12px;
    height: 12px;
    background-color: #000;
    margin: 0 8px;
    border-radius: 20px;
    cursor: pointer;
    border: none;
    transition: opacity 0.5s ease-in-out;
    @include media-breakpoint-down(md) {
        width: 8px;
        height: 8px;
        margin: 0 5px;
    }
}

.dots li.active {
    // width: 25px;
    background-color: rgba(217, 217, 217, 0.8);
}

.indicator {
    height: 12px;
    width: 12px;
    margin: 0 8px;
    background-color: #000;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    border: none;
    transition: opacity 0.5s ease-in-out;
    @include media-breakpoint-down(md) {
        height: 2px;
        width: 2px;
        margin: 0 2px;
        border-radius: 15px;
    }
}

.indicator-inactive {
    background-color: rgba(217, 217, 217, 0.8);
}

///////////////////////////////////////////////////////////////////////////////////////////////

.slider-container {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.slide {
    min-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: opacity 0.5s ease-in-out;
}

.prev,
.next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    padding: 16px;
    cursor: pointer;
    border: none;
    background: none;
    outline: none;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    background-color: $black;
    color: $white;
    @include media-breakpoint-down(md) {
        background-color: unset;
        color: unset;
        font-size: 20px;
    }
}

.prev.show,
.next.show {
    opacity: 1;
}

.prev {
    left: 16px;
}

.next {
    right: 16px;
}

i {
    width: 15px;
}
