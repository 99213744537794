.row-layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 56px;
    @media (max-width: 992px) {
        flex-direction: column;
    }
}

.content-layout {
    width: 100%;
}

.blogs-layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    @media (max-width: 1200px) {
        width: 70%;
    }
    @media (max-width: 1090px) {
        width: 88%;
    }
    @media (max-width: 992px) {
        width: 100%;
    }
}
