.card_create {
    display: flex;
    flex-direction: column;
    margin: 0 158px;
    height: 100vh;
    justify-content: center;
    align-items: center;
    gap: 40px;
    @media (max-width: 767.98px) {
        margin: 0 36px;
    }

    @media (min-width: 768px) {
        margin: 0 100px;
    }

    @media (min-width: 992px) {
        margin: 0 158px;
    }

    .head_content {
        display: flex;
        flex-direction: row;
        justify-items: center;
        padding: 16px;
        width: 100%;
        gap: 10px;
        color: #000;

        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
        .text_back {
            cursor: pointer;
        }
        @media (max-width: 767.98px) {
            font-size: 12px;
        }
    }

    .head_textcard {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        width: 100%;
        h2 {
            color: #000;
            text-align: center;
            margin: 0px;
            /* Header */

            font-size: 36px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            @media (max-width: 767.98px) {
                font-size: 18px;
            }
        }
        p {
            color: #000;
            margin: 0px;
            text-align: center;
            /* Normal */

            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            @media (max-width: 767.98px) {
                font-size: 12px;
            }
        }
    }

    form {
        width: 60%;
        @media (max-width: 767.98px) {
            width: 100%;
        }
        @media (min-width: 992px) {
            width: 50%;
        }
    }
    .ant-form-item-explain,
    .ant-form-item-extra,
    .ant-form-item-validate-message {
        text-align: left;
        font-size: 12px;

        @media (max-width: 767.98px) {
            font-size: 10px;
        }
    }
}
